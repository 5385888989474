
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { stringLimit } from '@/utils/string'
import { Edit, Delete, CloseBold } from '@element-plus/icons'
import ColorForm from './ColorForm.vue'
import SizeForm from './SizeForm.vue'
import { ElMessageBox } from 'element-plus'
import useCatalogVariantRepositories from '@/repositories/useCatalogVariantRepositories'
import { useNotification } from '@/composables'
import EditVariantForm from './EditVariantForm.vue'
import { Mixed, mixer } from '@/utils/variants'
import BulkUpdate from './BulkUpdate.vue'
import AddSetCost from './AddSetCost.vue'
import { formatterDollars } from '@/utils/formatter'

import { useRoute } from 'vue-router'
export interface Attribute {
  name: string
  code: string
  value: string
  cost: string
}
export default defineComponent({
  components: {
    CloseBold,
    ColorForm,
    SizeForm,
    EditVariantForm,
    Edit,
    Delete,
    BulkUpdate,
    AddSetCost,
  },
  props: {
    dataSet: {
      type: Object,
    },
    defaultCostBy: {
      type: String,
      default: 'size',
    },
    colors: {
      type: Array,
      default: () => [],
    },
    sizes: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Array,
      default: () => [],
    },
    productCode: {
      type: String,
    },
    typeAction: {
      type: String,
      default: 'create',
    },
    designs: {
      type: Array,
      default: () => []
    },
    catalogId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { deleteByIdVariant } = useCatalogVariantRepositories()
    const costBy = ref<string | undefined>(props.defaultCostBy)
    const colorsValue = ref<any[]>([...props.colors])
    const sizesValue = ref<any[]>([...props.sizes])
    const variants = ref<any[]>([...props.values])
    const artworks = ref<any[]>([...props.designs])
    const addVariants = ref<any[]>([])
    // const updateVariants = ref<any[]>([])
    const deleteVariantIds = ref<string[]>([])
    const dialog = ref(false)
    const options = ref([
      {
        title: 'Cost by size',
        value: 'size',
      },
      {
        title: 'Cost by color',
        value: 'color',
      },
    ])
    const { params } = useRoute()
    const sizeDialog = ref<InstanceType<typeof SizeForm>>()
    const colorDialog = ref<InstanceType<typeof ColorForm>>()
    const openAttributeDialog = (tartget: string) => {
      if (tartget === 'color') {
        colorDialog.value?.toggle()
      } else {
        sizeDialog.value?.toggle()
      }
    }
    const changeCostBy = () => {
      emit('changeCostBy', costBy.value)
    }

    const selectedIds = computed(() => {
      return multipleSelection.value.map((variant: any) => variant._id)
    })

    const catalogAttribute = ref<any>(null)
    const catalogVariant = ref<any>(null)
    const addAttribute = (type: string, attr: Attribute) => {
      // Add new attribute value to target product attribute
      if (type === 'color') {
        colorsValue.value.push(attr)
      } else {
        sizesValue.value.push(attr)
      }

      if (props.typeAction === 'create') {
        if (colorsValue.value.length && sizesValue.value.length) {
          createNewAttribute()
        }
      } else if (props.typeAction === 'edit') {
        if (colorsValue.value.length && sizesValue.value.length) {
          addValueToExistedAttr(type, attr)
        }
      }
    }

    // Trường hợp này xử lý khi thêm mới toàn bộ attribute
    const createNewAttribute = () => {
      const mixed: Mixed = { size: sizesValue.value, color: colorsValue.value }
      mix(mixed)
    }

    const sliceVariant = (index: number) => {
      variants.value?.splice(index, 1)
    }

    // Trường hợp này thì mix lại thêm variant rồi gửi bulk insert variants lên
    const addValueToExistedAttr = (type: string, value: Attribute) => {
      let mixed: Mixed = { size: [], color: [] }
      if (type === 'size') {
        mixed = { size: [value], color: colorsValue.value }
      } else {
        mixed = { color: [value], size: sizesValue.value }
      }
      mix(mixed)
    }

    const mix = (mixed: Mixed) => {
      // Mix variants
      let newVariants = mixer(mixed, {
        inStock: true,
        description: '',
        costBy: costBy.value,
        productCode: props.productCode,
        catalog: params.id,
      })
      // preview
      if (props.typeAction === 'create') {
        variants.value = newVariants
      } else {
        variants.value = variants.value.concat(newVariants)
      }
      addVariants.value = newVariants
    }

    // Xóa 1 attribute thì xóa toàn bộ variants có attribute đó trong product
    const deleteAttribute = async (target: string, name: string) => {
      if (target === 'size') {
        let index = sizesValue.value.findIndex(size => size.name === name)
        if (index > -1) {
          console.log('delete size', index, name)
          sizesValue.value.splice(index, 1)
        }
        const ids = []
        for (const variant of variants.value) {
          if (variant?.size?.name && variant.size.name === name) {
            ids.push(variant?._id || variant?.catalogVariantCode)
          }
        }
        deleteVariantIds.value = deleteVariantIds.value.concat(ids)
      } else {
        let index = colorsValue.value.findIndex(color => color.name === name)
        if (index > -1) {
          console.log('delete color', index, name)
          colorsValue.value.splice(index, 1)
        }
        const ids = []
        for (const variant of variants.value) {
          if (variant?.color?.name && variant.color.name === name) {
            ids.push(variant?._id || variant?.catalogVariantCode)
          }
        }
        deleteVariantIds.value = deleteVariantIds.value.concat(ids)
      }
      previewDeleteVariants() // xóa tạm variant đi để preview trước khi save
    }

    const previewDeleteVariants = () => {
      for (let index = 0; index < deleteVariantIds.value.length; index++) {
        let variantIndex = variants.value.findIndex(
          variant =>
            variant._id === deleteVariantIds.value[index] ||
            variant.catalogVariantCode === deleteVariantIds.value[index]
        )
        if (variantIndex > -1 && deleteVariantIds.value[index]) {
          variants.value.splice(variantIndex, 1)
        }
      }
      emit(
        'callback',
        colorsValue,
        sizesValue,
        addVariants.value,
        deleteVariantIds.value
      )
    }

    watch(variants, () => {
      emit(
        'callback',
        colorsValue,
        sizesValue,
        addVariants.value,
        deleteVariantIds.value
      )
    })

    const editVariantDialog = ref<InstanceType<typeof EditVariantForm>>()
    const openEditVariantDialog = (id: string) => {
      editVariantDialog.value?.toggle(id, params.id as string)
    }

    const { error, success } = useNotification()
    const deleteVariant = (id: string) => {
      ElMessageBox.confirm('Are you sure you want to delete variant?')
        .then(async () => {
          const { status, data } = await deleteByIdVariant(id)
          if (status === 200) {
            window.location.reload()
            success('Deleted')
          } else {
            error(data.message)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    let multipleSelection = ref([])
    const handleSelectionChange = (val: []) => {
      multipleSelection.value = val
    }

    const addSetCostDialog = ref<InstanceType<typeof AddSetCost>>()
    const onSelectedAction = (action: string) => {
      addSetCostDialog.value?.toggle(action)
    }

    return {
      dialog,
      options,
      sizeDialog,
      colorDialog,
      costBy,
      sizesValue,
      colorsValue,
      variants,
      artworks,
      catalogAttribute,
      editVariantDialog,
      catalogVariant,
      multipleSelection,
      addSetCostDialog,
      selectedIds,
      formatterDollars,
      openAttributeDialog,
      changeCostBy,
      addAttribute,
      deleteVariant,
      deleteAttribute,
      openEditVariantDialog,
      stringLimit,
      addValueToExistedAttr,
      sliceVariant,
      handleSelectionChange,
      onSelectedAction,
    }
  },
})
