
import { defineComponent, toRef, watch } from 'vue'
import { ArrowDown } from '@element-plus/icons'

export default defineComponent({
  components: {
    ArrowDown,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const multipleSelection = toRef(props, 'selected')

    watch(multipleSelection, () => {
      console.log(multipleSelection)
    })
    const submit = (action:string) => {
      emit('selectedAction', action)
    }

    return {
      multipleSelection,
      submit,
    }
  },
})
