
import { Edit, Delete, CloseBold } from '@element-plus/icons'
import UploadImage from '@/components/common/UploadImage.vue'
import { reactive, defineComponent, ref } from 'vue'
import { formatterDollars } from '@/utils/formatter'
export default defineComponent({
  components: { UploadImage, Edit, Delete },
  props: {
    dataSet: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const columns = [
      {
        title: 'Position',
        dataIndex: 'position',
        min_width: '100%',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        min_width: '100%',
      },
      {
        title: 'Width',
        dataIndex: 'width',
        min_width: '100%',
      },
      {
        title: 'Height',
        dataIndex: 'height',
        min_width: '100%',
      },
      {
        title: 'Format',
        dataIndex: 'format',
        min_width: '100%',
      },
      {
        title: 'DPI',
        dataIndex: 'dpi',
        min_width: '100%',
      },
      {
        title: 'Template',
        dataIndex: 'template',
        min_width: '100%',
      },
      {
        title: 'Additional Cost',
        dataIndex: 'additional_cost',
        min_width: '100%',
      },
      {
        title: 'Action',
        actions: [
          {
            type: 'edit',
          },
          {
            type: 'delete',
          },
        ],
      },
    ]
    const dialog = ref(false)
    const options = [
      {
        title: 'Artwork 1',
        value: 'artwork-1',
      },
      {
        title: 'Artwork 2',
        value: 'artwork-2',
      },
      {
        title: 'Artwork 3',
        value: 'artwork-3',
      },
      {
        title: 'Artwork 4',
        value: 'artwork-4',
      },
    ]
    const form = reactive({
      position: '',
      name: '',
      width: 0,
      height: 0,
      template: '',
      additional_cost: 0,
    })
    const callback = (setData: any, files: string[]) => {
      setData.template = files[0]
    }
    const close = () => {
      dialog.value = false
      Object.assign(form, {
        position: '',
        name: '',
        width: '',
        height: '',
        template: '',
        additional_cost: '',
      })
    }
    const status = reactive({
      status: 'add',
      index: -1,
      isExists: false,
    })
    const editDesign = (value: any, i: number) => {
      Object.assign(form, value)
      dialog.value = true
      status.status = 'edit'
      status.index = i
    }
    const addDesign = () => {
      dialog.value = true
      status.status = 'add'
      status.index = -1
    }
    const dialogConfirm = reactive({
      status: false,
      index: -1,
    })
    const delDesign = (i: number) => {
      let ds = props.dataSet as any[]
      ds.splice(i, 1)
      Object.assign(props.dataSet, ds)
    }
    const validateArtworkName = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error('Please input name'))
      } else {
        checkExists(value)
        if (status.isExists) {
          callback(new Error('Please enter other name !'))
        }
        callback()
      }
    }
    const checkExists = (name: string) => {
      if (status.index === -1)
        status.isExists = props.dataSet.some((e: any) => e.name === name)
      else
        status.isExists = props.dataSet.some(
          (e: any, i: number) => e.name === name && i !== status.index
        )
    }
    const rules = {
      position: [
        {
          required: true,
          message: 'Please input position',
          trigger: 'change',
        },
      ],
      name: [
        {
          validator: validateArtworkName,
          trigger: 'blur',
        },
      ],
      height: [
        {
          required: true,
          message: 'Please input height',
          trigger: 'blur',
        },
      ],
      width: [
        {
          required: true,
          message: 'Please input width',
          trigger: 'blur',
        },
      ],
      additional_cost: [
        {
          required: true,
          message: 'Please input additional cost',
          trigger: 'blur',
        },
      ],
    }
    console.log('designs -->', props.dataSet)

    const artwork = ref<any>(null)
    const save = () => {
      artwork.value?.validate((valid: any) => {
        console.log('valid form', valid)

        if (valid) {
          const obj = { ...form }
          emit('callback', obj, props.dataSet, status)
          dialog.value = false
          Object.assign(form, {
            position: '',
            name: '',
            format: '',
            dpi: '',
            width: '',
            height: '',
            template: '',
            additional_cost: '',
          })
        }
      })
    }
    return {
      columns,
      dialog,
      options,
      form,
      callback,
      close,
      save,
      editDesign,
      status,
      addDesign,
      delDesign,
      dialogConfirm,
      rules,
      artwork,
      formatterDollars,
    }
  },
})
